import axios from 'axios';
import { API_URL } from '../../config';
import {
  CREATE_ADMIN_SUCCESS,
  CREATE_ADMIN_FAIL,
  GET_ADMIN_LIST,
  GET_CLIENT_LIST,
  GET_SINGLE_ADMIN,
  CREATE_CONTRACTOR_SUCCESS,
  CREATE_CLIENT_SUCCESS,
  JOB_CREATED_SUCCESSFULLY,
  START_LOADING,
  STOP_LOADING,
  GET_CONTRACTOR_LIST,
  DELETE_ADMIN_SUCCESSFULLY,
  DELETE_CONTRACTOR_SUCCESSFULLY,
  GET_SINGLE_CONTRACTOR,
  GET_ALL_JOB,
  DELETE_JOB_SUCCESSFULLY,
  VIEW_JOB_SUCCESSFULLY,
  UPLAOD_DOCUMENT_SUCCESSFULLY,
  GET_ALL_DOCUMENTS,
  GET_ALL_HOLIDAY_LIST,
  APPROVED_SUCCESSFULLY,
  DELETE_DOCUMENT_SUCCESSFULLY,
  GET_ISSUE_SUCCESS,
  GET_ISSUE_REQUEST,
  ISSUE_SAVE_SUCCESSFULLY,
  GET_FORM_SUCCESSFULLY,
  GET_PRODUCT_SUCCESSFULLY,
  GET_CATEGORY_SUCCESSFULLY,
  CREATE_PRODUCT_SUCCESSFULLY,
  CREATE_PRODUCTLIST_SUCCESSFULLY,
  GET_SUBFORMS_REQUEST,
  GET_SUBFORMS_SUCCESS,
  GET_QUESTIONS_SUCCESS,
  GET_QUESTIONS_REQUEST,
  GET_JOBSECTION_SUCCESS,
  GET_JOBSECTION_REQUEST,
  EDIT_VIEWJOB_SUCCESSFULLY,
  GET_REPORTS_SUCCESS,
  GET_REPORTS_REQUEST,
  GET_REPORTS_BY_ID_SUCCESS,
  GET_REPORTS_SECTION_REQUEST,
  GET_REPORTS_JOBTYPE_SUCCESS,
  GET_REPORTS_JOBTYPE_REQUEST,
  GET_REPORTS_ALLJOBTYPE_SUCCESS,
  GET_REPORTS_ALLJOBTYPE_REQUEST,
  GET_REPORTS_CHAT_SUCCESS,
  GET_REPORTS_CHAT_REQUEST,
  GET_TIMESHEET_REQUEST,
  GET_TIMESHEET_SUCCESS,
  GET_APPROVE_LIST_REQUEST,
  GET_CONTRACTOR_REF_NO_LIST_REQUEST,
  GET_CONTRACTOR_SECTION_SUCCESS,
  GET_REPORT_CHAT_SUCCESS,
  EDIT_REPORTCHAT_SUCCESSFULLY,
  GET_LATEST_ACTIVITY_SUCCESS,
  GET_SEARCH_SUCCESS,
  GET_ALL_TIMESHEET_SUCCESS,
  GET_ALL_JOBSECTION_SUCCESS,
  GET_NOTIFICATION_LIST_SUCCESS,
  GET_TIMESHEET_SEC_SUCCESS,
  GET_CONTRACTOR_REF_NO_LIST_REQUEST2,
  GET_CONTRACTOR_REF_NO_LIST_SUCCESS,
  ADD_TIMESHEET_SUCCESS,
  GET_DASHBOARD_DATA,
  TIMESHEET_DETAIL_ACCORDING_TO_DATE,
  GET_CALENDER_SUCCESS,
  GET_ADD_TIME_SUCCESS,
  GET_REPORTS_CHAT_REQUEST_PDF,
  GET_REPORTS_CHAT_SUCCESS_PDF,
  GET_ALL_SECTION_FOR_PDF,
  GET_ALL_SECTION_SUCCESS_PDF,
  JOB_MAINTAIN_STATE,
  UPDATE_RISK_START,
  UPDATE_RISK_SUCCESS,
  GLOBAL_FILTER
} from '../Constant/constant';
import { toast } from '../../components/ToastContainer/Toast';
import API from '../../Service/Api';


export const createAdmin = (adminData, history) => async (dispatch) => {
  try {
    const { data } = await API.post(`/admin/create`, adminData);
    dispatch({ type: CREATE_ADMIN_SUCCESS, payload: adminData });
    if (data?.status == '404') {
      // toast.error(data?.message);
    }
    if (data?.status == '200') {
      history.push('/admins/list');
    }
  } catch (error) {
    // toast.error(error?.message);
    dispatch({
      type: CREATE_ADMIN_FAIL,
      payload: error.message && error.message ? error.message : '',
    });
  }
};
export const getAdminList = () => async (dispatch) => {
  dispatch({ type: START_LOADING, payload: true });
  try {
    const { data } = await API.get(`/admin/adminList`);
    dispatch({ type: GET_ADMIN_LIST, payload: data });
    dispatch({ type: STOP_LOADING, payload: false });
  } catch (error) {
    // toast.error(error?.message);
  }
};
export const getClinetList = () => async (dispatch) => {
  dispatch({ type: START_LOADING, payload: true });
  try {
    const { data } = await API.get(`/admin/clientList`);
    dispatch({ type: GET_CLIENT_LIST, payload: data });
    dispatch({ type: STOP_LOADING, payload: false });
  } catch (error) {
    // toast.error(error?.message);
  }
};
export const createClient = (clientData, history) => async (dispatch) => {
  try {
    const { data } = await API.post(`/admin/createClient`, clientData);
    if (data?.status == '404') {
      // toast.error(data?.message);
    }
    if (data?.status == '200') {
      history.push('/clients/list');
    } else {
      // toast.error(data['message']);
    }
  } catch (error) {
    // toast.error(error?.message);
    dispatch({
      type: CREATE_ADMIN_FAIL,
      payload: error.message && error.message ? error.message : '',
    });
  }
};
export const getContractorList = () => async (dispatch) => {
  dispatch({ type: START_LOADING, payload: true });
  try {
    const { data } = await API.get(`/admin/contractorList`);

    dispatch({ type: GET_CONTRACTOR_LIST, payload: data });
    dispatch({ type: STOP_LOADING, payload: false });
  } catch (error) {
    // toast.error(error?.message);
  }
};
export const createContractor =
  (contractordata, history) => async (dispatch) => {
    try {
      const { data } = await API.post(
        `/admin/createContractor`,
        contractordata
      );
      if (data?.status == '404') {
        // toast.error(data?.message);
      }
      if (data?.status == '200') {
        dispatch({ type: CREATE_CONTRACTOR_SUCCESS, payload: {} });
        history.push('/contractors/list');
      }
    } catch (error) {
      // toast.error(error?.message);
    }
  };
export const deleteAdmin = (id) => async (dispatch) => {
  dispatch({ type: START_LOADING, payload: true });
  try {
    const { data } = await API.delete(`/admin/deleteAdmin/${id}`);
    dispatch({ type: DELETE_ADMIN_SUCCESSFULLY, payload: [] });
    dispatch({ type: STOP_LOADING, payload: false });
  } catch (error) {
    // toast.error(error?.message);
  }
};
export const deleteContractor = (id) => async (dispatch) => {
  dispatch({ type: START_LOADING, payload: true });
  try {
    const { data } = await API.delete(`/admin/deleteContractor/${id}`);
    dispatch({ type: DELETE_CONTRACTOR_SUCCESSFULLY, payload: [] });
    dispatch({ type: STOP_LOADING, payload: false });
  } catch (error) {
    // toast.error(error?.message);
  }
};
export const getAdminById = (id) => async (dispatch) => {
  dispatch({ type: START_LOADING, payload: true });
  try {
    const { data } = await API.get(`/admin/getAdmin/${id}`);
    dispatch({ type: STOP_LOADING, payload: false });
    return data?.data;
  } catch (error) {
    // toast.error(error?.message);
  }
};
export const getContractorById = (id) => async (dispatch) => {
  dispatch({ type: START_LOADING, payload: true });
  try {
    const { data } = await API.get(`/admin/getContarctor/${id}`);
    dispatch({ type: STOP_LOADING, payload: false });
    return data?.data;
  } catch (error) {
    // toast.error(error?.message);
  }
};
export const editContractor = (contractordata, history) => async (dispatch) => {
  try {
    const { data } = await API.put(`/admin/editContractor`, contractordata);
    if (data?.status == '404') {
      // toast.error(data?.message);
    }
    if (data?.status == '200') {
      dispatch({ type: CREATE_CONTRACTOR_SUCCESS, payload: {} });
      history.push('/contractors/list');
      toast.success(data?.message);
    }
  } catch (error) {
    // toast.error(error?.message);
  }
};
export const editAdmin = (admindata, history) => async (dispatch) => {
  try {
    const { data } = await API.put(`/admin/editAdmin`, admindata);
    if (data?.status == '404') {
      // toast.error(data?.message);
    }
    if (data?.status == '200') {
      dispatch({ type: CREATE_CONTRACTOR_SUCCESS, payload: {} });
      history.push('/admins/list');
      toast.success(data?.message);
    }
  } catch (error) {
    // toast.error(error?.message);
  }
};
export const createJob = (jobdata, history, other) => async (dispatch) => {
  try {
    console.log(jobdata, "JOB_MAINTAIN_STATE")
    if (other == "maintain_state") {
      dispatch({ type: JOB_MAINTAIN_STATE, payload: jobdata });
    } else {
      const { data } = await API.post(`/admin/create/job`, jobdata);
      if (data?.status == '404') {
        // toast.error(data?.message);
      }
      if (data?.status == '200') {
        dispatch({ type: JOB_CREATED_SUCCESSFULLY, payload: {} });
        history.push(`/jobs/jobsdetail/${data.job_id}`);
        toast.success(data?.message);
      }
    }

  } catch (error) {
    // toast.error(error?.message);
  }
};
export const getAllJob = (page, search, postdata) => async (dispatch) => {
  dispatch({ type: START_LOADING, payload: true });
  try {
    const { data } = await API.post(
      `/admin/getalljob/${search == '' ? 'search' : search || "search"}/${page}`,
      postdata
    );

    dispatch({
      type: 'GET_ALL_JOB',
      payload: data?.data,
    });
    dispatch({ type: STOP_LOADING, payload: false });
    return data?.data;
  } catch (error) { }
};
export const deleteJob = (id) => async (dispatch) => {
  dispatch({ type: START_LOADING, payload: true });
  try {
    const { data } = await API.delete(`/admin/deletejob/${id}`);
    dispatch({ type: DELETE_JOB_SUCCESSFULLY, payload: [] });
    // dispatch(getAllJob(page, search));
  } catch (error) {
    dispatch({ type: STOP_LOADING, payload: false });
    // toast.error(error?.message);
  }
};
export const viewJob = (id) => async (dispatch) => {
  dispatch({ type: START_LOADING, payload: true });
  try {
    const { data } = await API.get(`/admin/viewjob/${id}`);
    dispatch({ type: VIEW_JOB_SUCCESSFULLY, payload: data });
    dispatch(getAllJob());
    dispatch({ type: STOP_LOADING, payload: false });
  } catch (error) {
    // toast.error(error?.message);
  }
};

export const getCompleteForms = () => async (dispatch) => {
  dispatch({ type: START_LOADING, payload: true });
  try {
    const { data } = await API.get(`/admin/get_completeforms_data`);
    dispatch({ type: GET_FORM_SUCCESSFULLY, payload2: data });
    dispatch(getAllJob());
    dispatch({ type: STOP_LOADING, payload: false });
  } catch (error) {
    // toast.error(error?.message);
  }
};

export const uploadDocument = (formData) => async (dispatch) => {
  dispatch({ type: START_LOADING, payload: true });
  try {
    const { data } = await API.post(`/admin/addDocument/`, formData);
    dispatch({ type: UPLAOD_DOCUMENT_SUCCESSFULLY, payload: data });
    dispatch({ type: STOP_LOADING, payload: false });
    dispatch(getAllDocument());
  } catch (error) {
    console.log(error);
    // toast.error(error?.message);
  }
};
export const getAllDocument = () => async (dispatch) => {
  dispatch({ type: START_LOADING, payload: true });
  try {
    const { data } = await API.get(`/admin/getdocumentList`);
    dispatch({
      type: 'GET_ALL_DOCUMENTS',
      payload: data?.data,
    });
    dispatch({ type: STOP_LOADING, payload: false });
    return data?.data;
  } catch (error) {
    console.log('err', error);
    // toast.error(error?.message);
  }
};
export const getAllHoliday = () => async (dispatch) => {
  dispatch({ type: START_LOADING, payload: true });
  try {
    const { data } = await API.get(`/admin/getHolidayList`);

    dispatch({
      type: GET_ALL_HOLIDAY_LIST,
      payload: data?.data,
    });
    dispatch({ type: STOP_LOADING, payload: false });
    return data?.data;
  } catch (error) {
    // toast.error(error?.message);
  }
};
export const approveoreject = (approvedata) => async (dispatch) => {
  try {
    const { data } = await API.post(`/admin/approveordecline`, approvedata);
    if (data?.status == '200') {
      dispatch({ type: APPROVED_SUCCESSFULLY, payload: {} });
      toast.success(data?.message);
    }
  } catch (error) {
    // toast.error(error?.message);
  }
};
export const deleteDocument = (id) => async (dispatch) => {
  dispatch({ type: START_LOADING, payload: true });
  try {
    const { data } = await API.delete(`/admin/deletedocument/${id}`);
    dispatch({ type: DELETE_DOCUMENT_SUCCESSFULLY, payload: [] });
    dispatch(getAllDocument());
    dispatch({ type: STOP_LOADING, payload: false });
  } catch (error) {
    // toast.error(error?.message);
  }
};
export const getClientById = (id) => async (dispatch) => {
  dispatch({ type: START_LOADING, payload: true });
  try {
    const { data } = await API.get(`/admin/getClient/${id}`);
    dispatch({ type: STOP_LOADING, payload: false });
    return data?.data;
  } catch (error) {
    // toast.error(error?.message);
  }
};
export const editClient = (clientdata, history) => async (dispatch) => {
  try {
    const { data } = await API.put(`/admin/editClient`, clientdata);
    if (data?.status == '404') {
      // toast.error(data?.message);
    }
    if (data?.status == '200') {
      dispatch({ type: 'EDIT_CLIENT_SUCCESSFULLY', payload: {} });
      history.push('/clients/list');
      toast.success(data?.message);
      dispatch(getClinetList());
    }
  } catch (error) {
    // toast.error(error?.message);
  }
};
export const deleteClient = (id) => async (dispatch) => {
  dispatch({ type: START_LOADING, payload: true });
  try {
    const { data } = await API.delete(`/admin/deleteClient/${id}`);
    dispatch({ type: 'DELETE_CLIENT_SUCCESSFULLY', payload: [] });
    dispatch(getClinetList());
    dispatch({ type: STOP_LOADING, payload: false });
  } catch (error) {
    // toast.error(error?.message);
  }
};
export const resetPassword = (resetdata) => async (dispatch) => {
  try {
    const { data } = await API.post(`/app/reset_password`, resetdata);
    if (data?.statusCode == '400') {
      // toast.error(data?.message);
    }
    if (data?.statusCode == '200') {
      toast.success(data['message']);
    } else {
      // toast.error(data['message']);
    }
  } catch (error) {
    // toast.error(error?.message);
    dispatch({
      type: 'RESET_PASSWORD_FAIL',
      payload: error.message && error.message ? error.message : '',
    });
  }
};
export const getChatList = (id) => async (dispatch) => {
  try {
    const { data } = await API.get(`/admin/chatList/${id}`);

    return data?.data;
  } catch (error) {
    console.log('error', error);
    ////toast.error(error?.message)
  }
};
export const chat = (dataadded) => async (dispatch) => {
  try {
    const { data } = await API.post(`/admin/chat`, dataadded);

    dispatch({ type: 'CHAT_SAVE_SUCCESSFULLY', payload: [] });
    // return data?.data
  } catch (error) {
    console.log('error', error);
    ////toast.error(error?.message)
  }
};

export const getissue = () => async (dispatch) => {
  dispatch({ type: GET_ISSUE_REQUEST, payload: true });
  try {
    const { data } = await API.get(`/admin/getissue`);

    if (data.statusCode === 200) {
      dispatch({ type: GET_ISSUE_SUCCESS, payload: data });
    }
  } catch (error) {
    // toast.error(error?.message);
  }
};

export const IssuesCreate = (dataadded) => async (dispatch) => {
  try {
    const { data } = await API.post(`/admin/IssuesCreate`, dataadded);
    if (data.statusCode === 200) {
      toast.success(data['message']);
      dispatch(getissue());
    }
    if (data.statusCode === 400) {
      // toast.error(data['message']);
    }
  } catch (error) {
    console.log('error', error);
  }
};
export const deleteIssue = (id) => async (dispatch) => {
  try {
    const { data } = await API.get(`/admin/deleteissue/${id}`);
    if (data.statusCode === 200) {
      toast.success(data['message']);
      dispatch(getissue());
    }
  } catch (error) {
    // toast.error(error?.message);
  }
};

//get category
export const category = () => async (dispatch) => {
  dispatch({ type: GET_CATEGORY_SUCCESSFULLY, payload: true });
  try {
    const { data } = await API.get(`/admin/Categorylist`);

    if (data.statusCode === 200) {
      dispatch({ type: GET_CATEGORY_SUCCESSFULLY, payload: data });
    }
  } catch (error) {
    // toast.error(error?.message);
  }
};
//create category
export const CategoryCreate = (dataadded) => async (dispatch) => {
  try {
    const { data } = await API.post(`/admin/Categoryadd`, dataadded);
    if (data.statusCode === 200) {
      toast.success(data['message']);
      dispatch(category());
    }
    if (data.statusCode === 400) {
      // toast.error(data['message']);
    }
  } catch (error) {
    console.log('error', error);
  }
};

//delete category
export const deletecategory = (id) => async (dispatch) => {
  try {
    const { data } = await API.get(`/admin/Categorydelete/${id}`);
    if (data.statusCode === 200) {
      toast.success(data['message']);
      dispatch(category());
    }
  } catch (error) {
    // toast.error(error?.message);
  }
};
//get product

export const getProducts = () => async (dispatch) => {
  dispatch({ type: START_LOADING, payload: true });
  try {
    const { data } = await API.get(`/admin/get_product_data`);
    dispatch({ type: GET_PRODUCT_SUCCESSFULLY, payload: data });
    dispatch(getAllJob());
    dispatch({ type: STOP_LOADING, payload: false });
  } catch (error) {
    // toast.error(error?.message);
  }
};
//prodcut list

export const get_product_listdata = () => async (dispatch) => {
  dispatch({ type: CREATE_PRODUCTLIST_SUCCESSFULLY, payload: true });
  try {
    const { data } = await API.get(`/admin/get_product_list`);
    if (data.statusCode === 200) {
      dispatch({ type: CREATE_PRODUCTLIST_SUCCESSFULLY, payload: data });
    }
  } catch (error) {
    // toast.error(error?.message);
  }
};

//create product

export const productCreate = (dataadded, history) => async (dispatch) => {
  try {
    const { data } = await API.post(`/admin/productCreate`, dataadded);

    if (data?.status == '200') {
      history.push('products/list');
    }
  } catch (error) {
    // toast.error(error?.message);
    dispatch({
      type: CREATE_ADMIN_FAIL,
      payload: error.message && error.message ? error.message : '',
    });
  }
};
//delete product

export const deleteproduct = (id) => async (dispatch) => {
  try {
    const { data } = await API.get(`/admin/deleteproduct/${id}`);
    if (data.statusCode === 200) {
      toast.success(data['message']);
      dispatch(get_product_listdata());
    } else {
      toast.success(data['message']);
    }
  } catch (error) {
    // toast.error(error?.message);
  }
};

//GET PRODUCTBYID
export const getproductbyid = (id) => async (dispatch) => {
  dispatch({ type: START_LOADING, payload: true });
  try {
    const { data } = await API.get(`/admin/getproductbyid/${id}`);
    dispatch({ type: STOP_LOADING, payload: false });
    return data?.data;
  } catch (error) {
    // toast.error(error?.message);
  }
};

export const getSubForms = (form_id, section_id) => async (dispatch) => {
  dispatch({ type: GET_SUBFORMS_REQUEST, payload: true });
  try {
    const { data } = await API.get(
      `/admin/subformlist/${form_id}/${section_id}`
    );

    if (data.statusCode === 200) {
      dispatch({ type: GET_SUBFORMS_SUCCESS, payload: data });
    }
  } catch (error) {
    // toast.error(error?.message);
  }
};
//EDIT PRODUCT
export const editPRODUCT = (productdata, history) => async (dispatch) => {
  try {
    const { data } = await API.put(`/admin/Updateproduct`, productdata);

    if (data?.statusCode == '404') {
      // toast.error(data?.message);
    }

    if (data?.statusCode == '200') {
      dispatch({ type: CREATE_PRODUCTLIST_SUCCESSFULLY, payload: {} });
      history.push('/products/list');
      toast.success(data?.message);
    }
  } catch (error) {
    // toast.error(error?.message);
  }
};

export const getCompleteQuestions =
  (sub_form_id, section_id) => async (dispatch) => {
    dispatch({ type: GET_QUESTIONS_REQUEST, payload: true });
    try {
      const { data } = await API.get(
        `/admin/questionList/${sub_form_id}/${section_id}`
      );

      if (data.statusCode === 200) {
        dispatch({ type: GET_QUESTIONS_SUCCESS, payload: data });
      }
    } catch (error) {
      // toast.error(error?.message);
    }
  };

export const getdetailofjob = (id) => async (dispatch) => {
  dispatch({ type: GET_JOBSECTION_REQUEST, payload: true });
  try {
    const { data } = await API.get(`/admin/getjobdetailBYid/${id}`);

    if (data.statusCode === 200) {
      localStorage.removeItem('dataarr');
      dispatch({ type: GET_JOBSECTION_SUCCESS, payload: data });
      localStorage.setItem('dataarr', JSON.stringify(data.data));
      localStorage.setItem('lastname', 'hello buddy');
    }
  } catch (error) {
    // toast.error(error?.message);
  }
};

export const editViewJob = (editData, history, id, other) => async (dispatch) => {
  try {
    if (other == "maintain_state") {
      dispatch({ type: JOB_MAINTAIN_STATE, payload: editData });
    } else {
      const { data } = await API.put(`/admin/editViewJob`, editData);
      if (data?.status == '404') {
      }
      if (data?.status == '200') {
        dispatch({ type: EDIT_VIEWJOB_SUCCESSFULLY, payload: {} });
        history.push(`/jobs/jobsdetail/${id}`)
        toast.success(data?.message);
      }
    }
  } catch (error) {
    // toast.error(error?.message);
  }
};

export const getReportsList = () => async (dispatch) => {
  dispatch({ type: GET_REPORTS_REQUEST, payload: true });
  try {
    const { data } = await API.get(`/admin/getUniqueClientJobComplete`);

    if (data.statusCode === 200) {
      dispatch({ type: GET_REPORTS_SUCCESS, payload: data });
    }
  } catch (error) {
    // toast.error(error?.message);
  }
};

export const getReportById = (id) => async (dispatch) => {
  dispatch({ type: GET_REPORTS_SECTION_REQUEST, payload: true });
  try {
    const { data } = await API.get(`/admin/getUniqueClientJobs/${id}`);

    if (data.statusCode === 200) {
      dispatch({ type: GET_REPORTS_BY_ID_SUCCESS, payload: data });
    }
  } catch (error) {
    // toast.error(error?.message);
  }
};

export const getReportsJobtype = (id) => async (dispatch) => {
  dispatch({ type: GET_REPORTS_JOBTYPE_REQUEST, payload: true });
  try {
    const { data } = await API.get(`/admin/getReportsJobType/${id}`);

    if (data.statusCode === 200) {
      dispatch({ type: GET_REPORTS_JOBTYPE_SUCCESS, payload: data });
    }
  } catch (error) {
    // toast.error(error?.message);
  }
};

export const getAllJobtype = (id, jobtype) => async (dispatch) => {
  dispatch({ type: GET_REPORTS_ALLJOBTYPE_REQUEST, payload: true });
  try {
    const { data } = await API.get(`/admin/getALLJobType/${id}/${jobtype}`);
    if (data.statusCode === 200) {
      dispatch({ type: GET_REPORTS_ALLJOBTYPE_SUCCESS, payload: data });
    }
  } catch (error) {
    // toast.error(error?.message);
  }
};

export const getAllChatReports = (id) => async (dispatch) => {
  dispatch({ type: GET_REPORTS_CHAT_REQUEST, payload: true });
  try {
    const { data } = await API.get(`/admin/getSectionById/${id}`);

    if (data.statusCode === 200) {
      dispatch({ type: GET_REPORTS_CHAT_SUCCESS, payload: data });
    }
  } catch (error) {
    // toast.error(error?.message);
  }
};

export const getTimeSheet = (data2) => async (dispatch) => {
  dispatch({ type: GET_TIMESHEET_REQUEST, payload: true });
  try {
    const { data } = await API.post(`/admin/gettimesheet`, data2);

    if (data.statusCode === 200) {
      dispatch({ type: GET_TIMESHEET_SUCCESS, payload: data });
    }
  } catch (error) {
    // toast.error(error?.message);
  }
};

export const getapproveholidaylist = () => async (dispatch) => {
  dispatch({ type: GET_APPROVE_LIST_REQUEST, payload: true });
  try {
    const { data } = await API.get('/admin/approveholidayrequestlist');
    if (data.statusCode === 200) {
      dispatch({ type: GET_APPROVE_LIST_REQUEST, payload: data });
    }
  } catch (error) {
    // toast.error(error?.message);
  }
};

export const getcontractorjobrefno = (contractor_id) => async (dispatch) => {
  try {
    const { data } = await API.get(`/admin/contractorjoblist/${contractor_id}`);

    if (data.statusCode === 200) {
      dispatch({ type: GET_CONTRACTOR_REF_NO_LIST_SUCCESS, payload: data });
    }
  } catch (error) {
    // toast.error(error?.message);
  }
};

export const getAddTimeData = (contractorId) => async (dispatch) => {
  try {
    console.log(contractorId, "handleDispatchAddTime contractorId contractorId admin")
    const { data } = await API.post(`/admin/getAddTimeData`, { contractorId });

    if (data.statusCode === 200) {
      dispatch({ type: GET_ADD_TIME_SUCCESS, payload: data });
    }
  } catch (error) {
    // toast.error(error?.message);
  }
};

export const getcontractorsection = (job_id) => async (dispatch) => {
  try {
    const { data } = await API.get(`/admin/getcontractorsection/${job_id}`);

    if (data.statusCode === 200) {
      dispatch({ type: GET_CONTRACTOR_SECTION_SUCCESS, payload: data });
    }
  } catch (error) {
    // toast.error(error?.message);
  }
};

export const getReportChatById = (id) => async (dispatch) => {
  try {
    const { data } = await API.post(`/admin/getReportChatById`, { id: id });

    if (data.statusCode === 200) {
      dispatch({ type: GET_REPORT_CHAT_SUCCESS, payload: data });
    }
  } catch (error) {
    // toast.error(error?.message);
  }
};

export const editReportChat = (editData, history) => async (dispatch) => {
  try {
    const data = await API.post(`/admin/editchat`, editData);
    if (data?.status == '404') {
      // toast.error(data?.message);
    }

    if (data?.status == '200') {
      dispatch({ type: EDIT_REPORTCHAT_SUCCESSFULLY, payload: {} });

      dispatch(getAllChatReports(localStorage.getItem('report_view_section')));
      toast.success(data?.message);
    }
  } catch (error) {
    // toast.error(error?.message);
  }
};

export const latestActivityData = (id) => async (dispatch) => {
  dispatch({ type: START_LOADING, payload: true });
  try {
    const { data } = await API.get(`/admin/gettimesheetdata/${id}`);

    if (data.statusCode === 200) {
      dispatch({ type: GET_LATEST_ACTIVITY_SUCCESS, payload: data });
    }
  } catch (error) {
    // toast.error(error?.message);
  }
  finally {
    dispatch({ type: START_LOADING, payload: false });
  }
};

export const searchJobData = (search) => async (dispatch) => {
  try {
    const { data } = await API.get(`/admin/searchjob/${search}`);

    if (data.statusCode === 200) {
      dispatch({ type: GET_SEARCH_SUCCESS, payload: data });
    }
  } catch (error) {
    // toast.error(error?.message);
  }
};

export const getAllTimesheetData = (search) => async (dispatch) => {
  dispatch({ type: START_LOADING, payload: true });
  try {
    const { data } = await API.get(`/admin/getalltimesheetdata`);

    if (data.statusCode === 200) {
      dispatch({ type: GET_ALL_TIMESHEET_SUCCESS, payload: data });
      dispatch({ type: START_LOADING, payload: false });
    }
  } catch (error) {
    // toast.error(error?.message);
  }
};

export const getAllJobSections = (page, search) => async (dispatch) => {
  dispatch({ type: START_LOADING, payload: true });
  try {
    const { data } = await API.get(
      `/admin/getAllJobSections/${search == '' ? 'search' : search || "search"}/${page}`
    );

    dispatch({ type: GET_ALL_JOBSECTION_SUCCESS, payload: data?.data });
    dispatch({ type: START_LOADING, payload: false });
  } catch (error) {
    // toast.error(error?.message);
  }
};

export const deleteReportData = (id) => async (dispatch) => {
  try {
    const { data } = await API.delete(`/admin/DeleteReport/${id}`);

    if (data?.statusCode == '200') {
      dispatch({ type: EDIT_REPORTCHAT_SUCCESSFULLY, payload: {} });

      dispatch(getAllChatReports(localStorage.getItem('report_view_section')));
      toast.success(data?.message);
    }
  } catch (error) {
    // toast.error(error?.message);
  }
};

export const NotificationsList = (info) => async (dispatch) => {
  dispatch({ type: GET_NOTIFICATION_LIST_SUCCESS, payload: true });
  try {
    const { data } = await API.post(`/admin/NotificationList/${info?.page || 1}`, { filter: info?.filter });
    if (data.statusCode === 200) {
      dispatch({ type: GET_NOTIFICATION_LIST_SUCCESS, payload: data });
    }
  } catch (error) {
    // toast.error(error?.message);
  }
};

export const getTimesheetForjobsection = (id) => async (dispatch) => {
  try {
    const { data } = await API.get(`/admin/gettimesheet/${id}`);

    if (data.statusCode === 200) {
      dispatch({ type: GET_TIMESHEET_SEC_SUCCESS, payload: data });
    }
  } catch (error) {
    // toast.error(error?.message);
  }
};

export const getcontractorjobrefnoData = (section_id) => async (dispatch) => {
  try {
    const { data } = await API.get(`/admin/contractorjoblist/${section_id}`);

    if (data.statusCode === 200) {
      dispatch({ type: GET_CONTRACTOR_REF_NO_LIST_REQUEST2, payload: data });
    }
  } catch (error) {
    // toast.error(error?.message);
  }
};

export const addTimeSheet = (additional) => async (dispatch) => {
  try {
    const { data } = await API.post(`/admin/addtimesheet`, additional);

    if (data.statusCode === 200) {
      dispatch({ type: ADD_TIMESHEET_SUCCESS, payload: data });
      toast.success('Time added successfully.');
    }
  } catch (error) {
    // toast.error(error?.message);
  }
};

export const DashBoardData = (year) => async (dispatch) => {
  try {
    const { data } = await API.get(`/admin/Dashboarddata/${year || 'year'}`);

    if (data.statusCode === 200) {
      dispatch({ type: GET_DASHBOARD_DATA, payload: data });
    }
  } catch (error) {
    // toast.error(error?.message);
  }
};
export const timesheetlistdata = (user_id, time) => async (dispatch) => {
  try {
    // const { data } = await API.post(
    //   `/admin/timeSheetdetails/${user_id}/${time}`
    // );
    const { data } = await API.post('/admin/timeSheetdetails', {
      user_id,
      time,
    });

    if (data.statusCode === 200) {
      dispatch({ type: TIMESHEET_DETAIL_ACCORDING_TO_DATE, payload: data });
    }
  } catch (error) {
    // toast.error(error?.message);
  }
};

export const getDeviceToken = () => async (dispatch) => {
  try {
    const { data } = await API.get(
      `/admin/updattetoken/${localStorage.getItem(
        'userId'
      )}/${localStorage.getItem('device_token')}`
    );
  } catch (error) {
    // toast.error(error?.message);
  }
};

export const getCalenderByMonthYear = (month_year) => async (dispatch) => {
  try {
    const { data } = await API.post(`/admin/Calender`, month_year);

    if (data.statusCode === 200) {
      dispatch({ type: GET_CALENDER_SUCCESS, payload: data });
      // toast.success('Time added successfully.');
    }
  } catch (error) {
    // toast.error(error?.message);
  }
};

export const deleteJobSection = (jobsec_id) => async (dispatch) => {
  try {
    const { data } = await API.get(`/admin/deleteJobSection/${jobsec_id}`);
  } catch (error) {
    // toast.error(error?.message);
  }
};


export const getAllChatReportsForPdf = (dataMain) => async (dispatch) => {
  dispatch({ type: GET_REPORTS_CHAT_REQUEST_PDF, payload: true });
  try {
    const { data } = await API.post(`/admin/getSectionForPdf`, dataMain);

    if (data.statusCode === 200) {
      dispatch({ type: GET_REPORTS_CHAT_SUCCESS_PDF, payload: data });
    }
  } catch (error) {
    // toast.error(error?.message);
  }
};

export const getAllSectionForPdf = (dataMain) => async (dispatch) => {
  dispatch({ type: GET_ALL_SECTION_FOR_PDF, payload: true });
  try {
    const { data } = await API.post(`/admin/getAllSectionForPdf`, dataMain);

    if (data.statusCode === 200) {
      dispatch({ type: GET_ALL_SECTION_SUCCESS_PDF, payload: data });
    }
  } catch (error) {
    // toast.error(error?.message);
  }
};

export const updateRiskAss = (dataMain) => async (dispatch) => {
  dispatch({ type: UPDATE_RISK_START, payload: true });
  try {
    const { data } = await API.post(`/admin/delete_risk`, dataMain);

    if (data.statusCode === 200) {
      dispatch({ type: UPDATE_RISK_SUCCESS, payload: data });
    }
  } catch (error) {
    // toast.error(error?.message);
  }
};

export const addFaq = (payload) => async (dispatch) => {
  try {
    console.log('work');
    let { data } = await API.post(`/admin/add_faqs`, payload);
    toast.success(data?.message);
  } catch (error) {
    toast.error(error?.message)
  }
};

export const editFaq = (payload) => async (dispatch) => {
  try {
    const { data } = await API.post(`/admin/update_faqs`, payload);
    toast.success(data?.message);
  } catch (error) {
  }
};

export const getFaq = () => async (dispatch) => {
  try {
    const { data } = await API.get(`/admin/get_faqs`);
    return data
  } catch (error) {
  }
};
export const deleteFaq = (id) => async (dispatch) => {
  try {
    const { data } = await API.delete(`/admin/delete_faqs/${id}`);
    toast.success(data?.message);
    return data
  } catch (error) {
  }
};

export const globalFilter = (data) => async (dispatch) => {
  try {
    dispatch({ type: GLOBAL_FILTER, payload: data });
  } catch (error) {
    console.log(error)
  }
};

